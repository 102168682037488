<template>
  <div>
    <a-row class="user-info">
      <a-col :span="24">
        <a-row class="title">登录账号</a-row>
        <a-row>
          <a-col :span="18">
            <span class="content">{{ user.account_number }}</span>
          </a-col>
        </a-row>

        <a-divider style="background:#9477db;" />

        <a-row class="title">登录密码</a-row>
        <a-row>
          <a-col :span="18" class="value">
            <span class="content">已设置</span>
          </a-col>
          <a-col :span="6" class="action">
            <a @click="showEditPasswordModal()">编辑</a>
          </a-col>
        </a-row>

        <a-divider style="background:#9477db;" />

        <a-row class="title">用户姓名</a-row>
        <a-row>
          <a-col :span="18" class="value">
            <div class="content">{{ user.full_name }}</div>
          </a-col>
          <a-col :span="6" class="action">
            <a @click="showEditFullNameModal()">编辑</a>
          </a-col>
        </a-row>

        <a-divider style="background:#9477db;" />

        <a-row class="title">手机号</a-row>
        <a-row>
          <a-col :span="18" class="value">
            <div class="content">{{ user.phone_number }}</div>
          </a-col>
          <a-col :span="6" class="action">
            <a @click="showVerifySmsCaptchaModal()">编辑</a>
          </a-col>
        </a-row>

        <a-divider style="background:#9477db;" />

        <a-row class="title">角色</a-row>
        <a-row>
          <a-col :span="18">
            <span class="content">{{ user.role }}</span>
          </a-col>
        </a-row>

      </a-col>
    </a-row>
    <!-- 编辑姓名 -->
    <edit-full-name
      v-if="isShowEditFullNameModal"
      :visible.sync="isShowEditFullNameModal"
      :id="userId"
      @completed="fetchData"
    />
    <!-- 编辑密码 -->
    <edit-password
      v-if="isShowEditPasswordModal"
      :visible.sync="isShowEditPasswordModal"
      :identification="identification"
      :id="userId"
      @completed="fetchData"
    />
    <!-- 编辑绑定手机 -->
    <edit-phone-number
      v-if="isShowEditPhoneNumberModal"
      :visible.sync="isShowEditPhoneNumberModal"
      :id="userId"
      :identification="identification"
      :old-phone-number="user.phone_number"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { findUserBasicInfo } from '@/api/user'
export default {
  name: 'UserInfo',
  components: {
    EditFullName: () => import('@/views/users/personal_info/EditFullNameModal'),
    EditPassword: () => import('@/views/users/personal_info/EditPasswordModal'),
    EditPhoneNumber: () => import('@/views/users/personal_info/EditPhoneNumberModal')
  },
  data() {
    return {
      user: {},
      identification: '',
      isShowEditUserNameModal: false,
      isShowEditFullNameModal: false,
      isShowVerifySmsCaptchaModal: false,
      isShowEditPasswordModal: false,
      isShowEditPhoneNumberModal: false,
      isShowEditEmailModal: false,
      nextVerifyId: ''
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    userId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    showEditFullNameModal() {
      this.isShowEditFullNameModal = true
    },

    showEditPasswordModal() {
      this.isShowEditPasswordModal = true
    },

    showVerifySmsCaptchaModal(e) {
      this.isShowEditPhoneNumberModal = true
    },

    fetchData() {
      findUserBasicInfo(this.userId).then((res) => {
        if (res.code === 0) {
          this.user = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-info {
  display: flex;
  margin: auto;
  padding: 50px 30px;
  width: 600px;

  .title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #1e1e1e;
  }

  .content {
    color: #1e1e1e;
  }

  .action {
    padding-right: 10px;
    text-align: right;
  }
}
</style>
